import { Injectable } from '@angular/core';
import { UserService } from 'src/app/core/services/user/user.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CountryCodeService {
    listCountryCode: string[] = ['US', 'VN'];
    signal = new BehaviorSubject<string | null>(null);
    coordinates = new BehaviorSubject<any | null>(null);
    locationData = new BehaviorSubject<any | null>(null);

    constructor(private http: HttpClient) {
        this.getRegional().subscribe({
            next: (res: any) => {
                if (res.success) {
                    if (this.listCountryCode.includes(res.data[0]?.country_code?.toUpperCase())) {
                        this.setCountryCode(res.data[0]?.country_code);
                        this.setCoordinates({ lon: res.data[0]?.longitude, lat: res.data[0]?.latitude });
                    } else {
                        this.setCountryCode(this.listCountryCode[0]);
                    }
                    this.setLocation(res.data[0]);
                } else this.setCountryCode(this.listCountryCode[0]);
            },
            error: () => {
                this.setCountryCode(this.listCountryCode[0]);
            }
        });
    }


    setCountryCode(countryCode: string | null) {
        this.signal.next(countryCode);
    }

    getCountryCode() {
        return this.signal.asObservable();
    }
    setCoordinates(lon_lat: any) {
        this.coordinates.next(lon_lat);
    }

    getCoordinates() {
        return this.coordinates.asObservable();
    }


    getRegional() {
        return this.http.get<any>(`${environment.baseURL}/regional/me`);
    }

    setLocation(currentLocation: any) {
        this.locationData.next(currentLocation);
    }

    getLocation() {
        return this.locationData.asObservable();
    }

}