<ng-container>
    <app-carousel [items]="airlineList" [itemsToShow]="4" [itemsToScroll]="1" [gap]="12" [itemTemplate]="customTemplate"
        [id]="'suggested-card'" [breakpoints]="[{ width: 1200, itemsToShow: 4 }, { width: 1000, itemsToShow: 3 }, { width: 540, itemsToShow: 2 }, { width: 0, itemsToShow: 1 }]">
    </app-carousel>
    <ng-template #customTemplate let-item let-index="index">
      <div
      class="airline-suggested flex flex-col !h-[335px] "
      style="
        border: #eaecf0;
        border-style: solid;
        border-width: thin;
        border-radius: 8px;
        background-color: white;
      "
      (click)="cardClickHandle(item?.absolute_link, item.module ?? '')"
    >
      <div style="height: calc(100% - 165px); width: 100%; min-height: calc(100% - 165px); max-height: calc(100% - 165px)">
        <img
          [src]="
            item?.image
              ? getImagePath(item.image)
              : defaultImage
          "
          style="height: 100%; width: 100%; object-fit: cover; border-radius: 8px 8px 0px 0px"
          [alt]="''"
          (error)="getDefaultImage($event)"
        />
      </div>
  
      <div
        class="t-content flex flex-col !p-4 bg-white gap-2 justify-between"
        style="height: 100%; border-radius: 0 0 8px 8px"
      >
        <div class="flex flex-col gap-1">
      
          <!-- title  -->
           <div class="relative">
            <p
            class="t-titile height-10 line-clamp-2"
            style="color: #101828; line-height: 20px; font-size: 14px; font-weight: 500; font: Inter"
            [tooltip]="item.title"  [isTruncated]="true"
          >
            {{ item.title }}
          </p>
           </div>
          
          <!-- Description -->
          <ng-container *ngIf="item.summary">
            
            <div
              *ngIf=" item?.summary &&
                getDescriptionFlight(item.summary)
              "
              class="items-center flex line-clamp-1"
            >
              <div
                [pTooltip]="getDescriptionFlight(item.summary)?.info?.airline_name ?? ''"
                tooltipPosition="top"
              >
                <img
                  [src]="getImagePath(getDescriptionFlight(item.summary)?.airline_image ?? '')"
                  style="height: 16px; width: 16px"
                  [alt]="getDescriptionFlight(item.summary)?.info?.airline_name"
                />
              </div>
              <p
                style="
                  color: #667085;
                  font-size: 12px;
                  line-height: 16px;
                  font-weight: 400;
                  font: Inter;
                  padding-left: 4px;
                "
                class="line-clamp-1"
              >
                | {{ getDescriptionFlight(item.summary)?.info?.cabin_class }}
              </p>
            </div>
            
          </ng-container>
        </div>
        <!-- price and button -->
        <div class="{{ item.price && item.price < 0 ? 'flex items-end' : '' }}">
          <div
          class="t-price flex flex-row gap-1 items-baseline"
          style="height: 28px"
          *ngIf="item.price && item.price >= 0"
        >
     
          <p
            class="t-price"
            style="color: #e04f16; font-size: 18px; font-weight: 600; line-height: 28px"
          >
            {{ item.price ?? 0 | currencyConversion }}
          </p>
         
        </div>


          <button
            class="btn-book-now"
            style="
              color: #1570ef;
              line-height: 20px;
              font-size: 14px;
              font-weight: 500;
              white-space: nowrap;
              width: 100%;
              background-color: #eff8ff;
              height: 28px;
              padding: 4px 8px 4px 8px;
              border-radius: 8px;
            "
          >
            {{
            'CROSS_SALE.BOOKING_NOW' | translate
              
            }}
          </button>
        </div>
      </div>
    </div>
  
    </ng-template>
</ng-container>