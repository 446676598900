import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '@env/environment';

@Component({
  selector: 'app-suggested-for-you',
  templateUrl: './suggested-for-you.component.html',
  styleUrls: ['./suggested-for-you.component.scss'],
})
export class SuggestedForYouComponent {
  // @Input() code: string;
  // @Input() country: string;

  listSuggestedForYous: any[] = [];
  baseUrl = environment.baseURL;
  responsiveOptions: any;
  slideshows: any[] = [];
  loading: boolean = true;
  isShow: boolean = false;
  isShowNextButton = false;
  isInitial: boolean = false;
  airlineList: any[];
  defaultImage = 'assets/images/background/cross-sale-default.png'

  ngOnInit(): void {
    this.getSuggestedForYou();
    this.initData();
    this.airlineList = this.listSuggestedForYous.map((item) => item.t2);
    this.responsiveOptions = [
      {
        breakpoint: '1200',
        numVisible: 3,
        numScroll: 1,
      },
      {
        breakpoint: '798px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '486px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
    this.isInitial = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (
    //   this.isInitial &&
    //   changes['country'] &&
    //   changes['country'].currentValue
    // ) {
    //   this.getSuggestedForYou();
    // }
  }

  getSuggestedForYou() {}
  cardClickHandle(bookingUrl: string | undefined, module: string): void {
    if (bookingUrl)
      window.open(`${environment.FLIGHT_URL}${bookingUrl}`, '_blank');
  }

  getDescriptionFlight(
    descript: string
  ):
    | {
        airline_image: string;
        info: { airline_name: string; cabin_class: string };
      }
    | undefined {
    let description = undefined;

    try {
      const descriptionObject = JSON.parse(descript);
      if (descriptionObject) {
        const info = String(descriptionObject.info).split('|');
        description = {
          airline_image: descriptionObject.airline_image,
          info: {
            airline_name: info[0] ?? '',
            cabin_class: info[1] ?? '',
          },
        };
      }
    } catch (error) {
      description = undefined;
    }

    return description;
  }
  getDefaultImage(event: any): void {
    event.target.src = 'assets/images/background/cross-sale-default.png';
  }

 


  getImagePath(imageId: string, option: 'web' | 'thumbnail' = 'web'): string {
    if (imageId.toLowerCase().includes('http')) {
      return imageId;
    } else {
      if (imageId) {
        if (imageId.includes('.')) {
          return `${environment.baseURL}/storage/files/${option}/${imageId}`;
        } else {
          return `${environment.baseURL}/storage/files/${option}/${imageId}.webp`;
        }
      } else {
        return '';
      }
    }
  }
  initData() {
    this.listSuggestedForYous = [
      {
        t1: 'a74631c6-f9bd-4432-8fee-1f0607e1b486',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=TK&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=TK&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'a74631c6-f9bd-4432-8fee-1f0607e1b486',
          module: 'FLIGHT',
          title: 'Bangkok (BKK) → Ho Chi Minh city (SGN)',
          summary:
            '{"airline_image":"vn_20241022T104922058144235_00000000-0000-0000-0000-00000000000_AirlineLogoTK.webp","info":"Turkish Airlines | Economy"}',
          image:
            'vn_20250124T143252200171806_00000000-0000-0000-0000-000000000000_HoChiMinhSGN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '2781fe19-a52f-4957-be0d-5917b29bae67',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=UIH&departure_city=Qui+Nhon&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VU&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=UIH&departure_city=Qui+Nhon&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VU&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '2781fe19-a52f-4957-be0d-5917b29bae67',
          module: 'FLIGHT',
          title: 'Qui Nhon (UIH) → Ho Chi Minh city (SGN)',
          summary:
            '{"airline_image":"vn_20241022T105029095310590_00000000-0000-0000-0000-000000000000_AirlineLogoVU.webp","info":"Vietravel Airlines | Economy"}',
          image:
            'vn_20250124T143252200171806_00000000-0000-0000-0000-000000000000_HoChiMinhSGN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '6ec0b6ad-23ac-4ddd-9022-7acb9880db3f',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=PremiumEconomy&airlines=VN&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=PremiumEconomy&airlines=VN&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '6ec0b6ad-23ac-4ddd-9022-7acb9880db3f',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Da Nang (DAD)',
          summary:
            '{"airline_image":"vn_20241022T105023170656733_00000000-0000-0000-0000-000000000000_AirlineLogoVN.webp","info":"Vietnam Airlines | Premium Economy"}',
          image:
            'vn_20250124T142951977000651_00000000-0000-0000-0000-000000000000_DaNangDAD',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '4a62d80a-4768-40af-a263-613ac232ef08',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=UIH&departure_city=Qui+Nhon&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=QH&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=UIH&departure_city=Qui+Nhon&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=QH&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '4a62d80a-4768-40af-a263-613ac232ef08',
          module: 'FLIGHT',
          title: 'Qui Nhon (UIH) → Ho Chi Minh city (SGN)',
          summary:
            '{"airline_image":"vn_20241022T104738017292739_00000000-0000-0000-0000-000000000000_AirlineLogoQH.webp","info":"Bamboo Airways | Economy"}',
          image:
            'vn_20250124T143252200171806_00000000-0000-0000-0000-000000000000_HoChiMinhSGN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '67ef44ce-082a-40d5-87ba-90085f8f2b34',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=3U&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=3U&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '67ef44ce-082a-40d5-87ba-90085f8f2b34',
          module: 'FLIGHT',
          title: 'Bangkok (BKK) → Ho Chi Minh city (SGN)',
          summary:
            '{"airline_image":"vn_20241022T095529202101052_00000000-0000-0000-0000-000000000000_AirlineLogo3U.webp","info":"Sichuan Airlines | Economy"}',
          image:
            'vn_20250124T143252200171806_00000000-0000-0000-0000-000000000000_HoChiMinhSGN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '2d3ac864-30c1-45bc-be97-829243171321',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=MF&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=MF&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '2d3ac864-30c1-45bc-be97-829243171321',
          module: 'FLIGHT',
          title: 'Bangkok (BKK) → Ho Chi Minh city (SGN)',
          summary:
            '{"airline_image":"vn_20241022T101243646930336_00000000-0000-0000-0000-000000000000_AirlineLogoMF.webp","info":"Xiamen Airlines | Economy"}',
          image:
            'vn_20250124T143252200171806_00000000-0000-0000-0000-000000000000_HoChiMinhSGN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '4a6cc49b-8d6d-4094-83b9-dfffebf7dcbd',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=HAN&departure_city=Ha+Noi&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=VN&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=HAN&departure_city=Ha+Noi&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=VN&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '4a6cc49b-8d6d-4094-83b9-dfffebf7dcbd',
          module: 'FLIGHT',
          title: 'Ha Noi (HAN) → Da Nang (DAD)',
          summary:
            '{"airline_image":"vn_20241022T105023170656733_00000000-0000-0000-0000-000000000000_AirlineLogoVN.webp","info":"Vietnam Airlines | Business"}',
          image:
            'vn_20250124T142951977000651_00000000-0000-0000-0000-000000000000_DaNangDAD',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '9d843a40-5fda-4c94-a952-4d95237c5be9',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=CXR&departure_city=Nha+Trang&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=QH&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=CXR&departure_city=Nha+Trang&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=QH&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '9d843a40-5fda-4c94-a952-4d95237c5be9',
          module: 'FLIGHT',
          title: 'Nha Trang (CXR) → Ho Chi Minh city (SGN)',
          summary:
            '{"airline_image":"vn_20241022T104738017292739_00000000-0000-0000-0000-000000000000_AirlineLogoQH.webp","info":"Bamboo Airways | Business"}',
          image:
            'vn_20250124T143252200171806_00000000-0000-0000-0000-000000000000_HoChiMinhSGN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '5e320d1a-453b-4136-9a94-ff7a4bf8e9f4',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=HAN&departure_city=Ha+Noi&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=QH&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=HAN&departure_city=Ha+Noi&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=QH&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '5e320d1a-453b-4136-9a94-ff7a4bf8e9f4',
          module: 'FLIGHT',
          title: 'Ha Noi (HAN) → Da Nang (DAD)',
          summary:
            '{"airline_image":"vn_20241022T104738017292739_00000000-0000-0000-0000-000000000000_AirlineLogoQH.webp","info":"Bamboo Airways | Business"}',
          image:
            'vn_20250124T142951977000651_00000000-0000-0000-0000-000000000000_DaNangDAD',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '6e4de529-f3be-406a-9617-820024c3ef66',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=ICN&departure_city=Seoul&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VJ&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=ICN&departure_city=Seoul&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VJ&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '6e4de529-f3be-406a-9617-820024c3ef66',
          module: 'FLIGHT',
          title: 'Seoul (ICN) → Da Nang (DAD)',
          summary:
            '{"airline_image":"vn_20241022T105019783688736_00000000-0000-0000-0000-000000000000_AirlineLogoVJ.webp","info":"Vietjet Air | Economy"}',
          image:
            'vn_20250124T142951977000651_00000000-0000-0000-0000-000000000000_DaNangDAD',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'e4a253f9-11a6-4504-9d1b-4ff54bd7574c',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=ICN&departure_city=Seoul&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=HX&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=ICN&departure_city=Seoul&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=HX&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'e4a253f9-11a6-4504-9d1b-4ff54bd7574c',
          module: 'FLIGHT',
          title: 'Seoul (ICN) → Da Nang (DAD)',
          summary:
            '{"airline_image":"vn_20241022T101012508121238_00000000-0000-0000-0000-000000000000_AirlineLogoHX.webp","info":"Hong Kong Airlines | Economy"}',
          image:
            'vn_20250124T142951977000651_00000000-0000-0000-0000-000000000000_DaNangDAD',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '0a828548-1df2-4282-96c9-3760b84afc15',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VU&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VU&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '0a828548-1df2-4282-96c9-3760b84afc15',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Da Nang (DAD)',
          summary:
            '{"airline_image":"vn_20241022T105029095310590_00000000-0000-0000-0000-000000000000_AirlineLogoVU.webp","info":"Vietravel Airlines | Economy"}',
          image:
            'vn_20250124T142951977000651_00000000-0000-0000-0000-000000000000_DaNangDAD',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'a79c2a50-b6a3-4730-be19-c6d39dbc2ebb',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VN&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VN&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'a79c2a50-b6a3-4730-be19-c6d39dbc2ebb',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Da Nang (DAD)',
          summary:
            '{"airline_image":"vn_20241022T105023170656733_00000000-0000-0000-0000-000000000000_AirlineLogoVN.webp","info":"Vietnam Airlines | Economy"}',
          image:
            'vn_20250124T142951977000651_00000000-0000-0000-0000-000000000000_DaNangDAD',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '3e436ee0-9082-4c5c-8f39-70746f0f6c2a',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=H1&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=H1&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '3e436ee0-9082-4c5c-8f39-70746f0f6c2a',
          module: 'FLIGHT',
          title: 'Bangkok (BKK) → Da Nang (DAD)',
          summary:
            '{"airline_image":"vn_20241022T100944039614568_00000000-0000-0000-0000-000000000000_AirlineLogoH1.webp","info":"Hahn Air | Economy"}',
          image:
            'vn_20250124T142951977000651_00000000-0000-0000-0000-000000000000_DaNangDAD',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '644b3338-cf58-491d-9599-669c03a71782',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=HPH&departure_city=Hai+Phong&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VN&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=HPH&departure_city=Hai+Phong&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VN&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '644b3338-cf58-491d-9599-669c03a71782',
          module: 'FLIGHT',
          title: 'Hai Phong (HPH) → Da Nang (DAD)',
          summary:
            '{"airline_image":"vn_20241022T105023170656733_00000000-0000-0000-0000-000000000000_AirlineLogoVN.webp","info":"Vietnam Airlines | Economy"}',
          image:
            'vn_20250124T142951977000651_00000000-0000-0000-0000-000000000000_DaNangDAD',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '8dd6d9b0-e779-4d63-9d06-a01eedfce502',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=VN&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=VN&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '8dd6d9b0-e779-4d63-9d06-a01eedfce502',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Da Nang (DAD)',
          summary:
            '{"airline_image":"vn_20241022T105023170656733_00000000-0000-0000-0000-000000000000_AirlineLogoVN.webp","info":"Vietnam Airlines | Business"}',
          image:
            'vn_20250124T142951977000651_00000000-0000-0000-0000-000000000000_DaNangDAD',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '117ef8c4-503d-4c31-b62e-51d83a9bd9c1',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=ICN&departure_city=Seoul&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=W2&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=ICN&departure_city=Seoul&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=W2&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '117ef8c4-503d-4c31-b62e-51d83a9bd9c1',
          module: 'FLIGHT',
          title: 'Seoul (ICN) → Da Nang (DAD)',
          summary:
            '{"airline_image":"vn_20241022T105033731693129_00000000-0000-0000-0000-000000000000_AirlineLogoW2.webp","info":"Flexflight | Economy"}',
          image:
            'vn_20250124T142951977000651_00000000-0000-0000-0000-000000000000_DaNangDAD',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '05d60bfd-cf6f-4c8b-8ac2-15261cf45485',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=HAN&departure_city=Ha+Noi&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VU&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=HAN&departure_city=Ha+Noi&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VU&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '05d60bfd-cf6f-4c8b-8ac2-15261cf45485',
          module: 'FLIGHT',
          title: 'Ha Noi (HAN) → Da Nang (DAD)',
          summary:
            '{"airline_image":"vn_20241022T105029095310590_00000000-0000-0000-0000-000000000000_AirlineLogoVU.webp","info":"Vietravel Airlines | Economy"}',
          image:
            'vn_20250124T142951977000651_00000000-0000-0000-0000-000000000000_DaNangDAD',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '47b8c601-9b61-4c07-98d0-d3141a362976',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=H1&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=DAD&arrived_city=Da+Nang&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=H1&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '47b8c601-9b61-4c07-98d0-d3141a362976',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Da Nang (DAD)',
          summary:
            '{"airline_image":"vn_20241022T100944039614568_00000000-0000-0000-0000-000000000000_AirlineLogoH1.webp","info":"Hahn Air | Economy"}',
          image:
            'vn_20250124T142951977000651_00000000-0000-0000-0000-000000000000_DaNangDAD',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '3421d248-99af-4495-9895-d36e6ab3c940',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=8M&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=8M&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '3421d248-99af-4495-9895-d36e6ab3c940',
          module: 'FLIGHT',
          title: 'Bangkok (BKK) → Ho Chi Minh city (SGN)',
          summary:
            '{"airline_image":"vn_20241022T095718081416996_00000000-0000-0000-0000-000000000000_AirlineLogo8M.webp","info":"Myanmar Airways Intl | Economy"}',
          image:
            'vn_20250124T143252200171806_00000000-0000-0000-0000-000000000000_HoChiMinhSGN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'f0e74715-82d2-4926-b4bf-a78eb573951f',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=CXR&departure_city=Nha+Trang&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=VJ&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=CXR&departure_city=Nha+Trang&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=VJ&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'f0e74715-82d2-4926-b4bf-a78eb573951f',
          module: 'FLIGHT',
          title: 'Nha Trang (CXR) → Ho Chi Minh city (SGN)',
          summary:
            '{"airline_image":"vn_20241022T105019783688736_00000000-0000-0000-0000-000000000000_AirlineLogoVJ.webp","info":"Vietjet Air | Business"}',
          image:
            'vn_20250124T143252200171806_00000000-0000-0000-0000-000000000000_HoChiMinhSGN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'fd721e53-42dd-41cc-9168-4f284349bd67',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=UIH&departure_city=Qui+Nhon&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VN&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=UIH&departure_city=Qui+Nhon&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VN&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'fd721e53-42dd-41cc-9168-4f284349bd67',
          module: 'FLIGHT',
          title: 'Qui Nhon (UIH) → Ho Chi Minh city (SGN)',
          summary:
            '{"airline_image":"vn_20241022T105023170656733_00000000-0000-0000-0000-000000000000_AirlineLogoVN.webp","info":"Vietnam Airlines | Economy"}',
          image:
            'vn_20250124T143252200171806_00000000-0000-0000-0000-000000000000_HoChiMinhSGN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '3e6d40a2-4eb3-4278-9bf9-48b3acbcd6ed',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=MU&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=MU&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '3e6d40a2-4eb3-4278-9bf9-48b3acbcd6ed',
          module: 'FLIGHT',
          title: 'Bangkok (BKK) → Ho Chi Minh city (SGN)',
          summary:
            '{"airline_image":"vn_20241022T101300256910722_00000000-0000-0000-0000-000000000000_AirlineLogoMU.webp","info":"China Eastern Airlines | Economy"}',
          image:
            'vn_20250124T143252200171806_00000000-0000-0000-0000-000000000000_HoChiMinhSGN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'f0a1b620-7cf3-4471-a780-86cfaf998734',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=HAN&departure_city=Ha+Noi&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=PremiumEconomy&airlines=VN&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=HAN&departure_city=Ha+Noi&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=PremiumEconomy&airlines=VN&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'f0a1b620-7cf3-4471-a780-86cfaf998734',
          module: 'FLIGHT',
          title: 'Ha Noi (HAN) → Ho Chi Minh city (SGN)',
          summary:
            '{"airline_image":"vn_20241022T105023170656733_00000000-0000-0000-0000-000000000000_AirlineLogoVN.webp","info":"Vietnam Airlines | Premium Economy"}',
          image:
            'vn_20250124T143252200171806_00000000-0000-0000-0000-000000000000_HoChiMinhSGN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'a18b1ea4-3197-4b74-94c5-250c1b872820',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=HAN&departure_city=Ha+Noi&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=VN&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=HAN&departure_city=Ha+Noi&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=VN&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'a18b1ea4-3197-4b74-94c5-250c1b872820',
          module: 'FLIGHT',
          title: 'Ha Noi (HAN) → Ho Chi Minh city (SGN)',
          summary:
            '{"airline_image":"vn_20241022T105023170656733_00000000-0000-0000-0000-000000000000_AirlineLogoVN.webp","info":"Vietnam Airlines | Business"}',
          image:
            'vn_20250124T143252200171806_00000000-0000-0000-0000-000000000000_HoChiMinhSGN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '21e0c4c2-a5ae-4af4-93ae-1d45bc23463e',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VN&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VN&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '21e0c4c2-a5ae-4af4-93ae-1d45bc23463e',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T105023170656733_00000000-0000-0000-0000-000000000000_AirlineLogoVN.webp","info":"Vietnam Airlines | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'ae748035-d2a0-4e04-92f6-227bb7fe790a',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=QH&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=QH&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'ae748035-d2a0-4e04-92f6-227bb7fe790a',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T104738017292739_00000000-0000-0000-0000-000000000000_AirlineLogoQH.webp","info":"Bamboo Airways | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'd7a3222a-fcbb-4647-8a6e-36087d0c737d',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VU&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VU&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'd7a3222a-fcbb-4647-8a6e-36087d0c737d',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T105029095310590_00000000-0000-0000-0000-000000000000_AirlineLogoVU.webp","info":"Vietravel Airlines | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '47f92d1e-49a5-4dbb-b2d1-45cbd35bf136',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=QH&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=QH&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '47f92d1e-49a5-4dbb-b2d1-45cbd35bf136',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T104738017292739_00000000-0000-0000-0000-000000000000_AirlineLogoQH.webp","info":"Bamboo Airways | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'aef081c4-9088-46d7-baad-7a7af213bfc8',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=QH&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=QH&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'aef081c4-9088-46d7-baad-7a7af213bfc8',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T104738017292739_00000000-0000-0000-0000-000000000000_AirlineLogoQH.webp","info":"Bamboo Airways | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'bb0703cc-8833-440b-a40b-0d78a2dc7836',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=QH&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=QH&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'bb0703cc-8833-440b-a40b-0d78a2dc7836',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T104738017292739_00000000-0000-0000-0000-000000000000_AirlineLogoQH.webp","info":"Bamboo Airways | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '0ad5d815-1d1d-48a5-8768-20cab33ea2e5',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=H1&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=H1&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '0ad5d815-1d1d-48a5-8768-20cab33ea2e5',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T100944039614568_00000000-0000-0000-0000-000000000000_AirlineLogoH1.webp","info":"Hahn Air | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '9bb1cf67-188a-4a14-acb2-b20ce6621b56',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VJ&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VJ&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '9bb1cf67-188a-4a14-acb2-b20ce6621b56',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T105019783688736_00000000-0000-0000-0000-000000000000_AirlineLogoVJ.webp","info":"Vietjet Air | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '24836410-4d89-4f5a-8d1c-3db3b543cef5',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=W2&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=W2&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '24836410-4d89-4f5a-8d1c-3db3b543cef5',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T105033731693129_00000000-0000-0000-0000-000000000000_AirlineLogoW2.webp","info":"Flexflight | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '5e36564d-f8bb-4f14-a76f-e5b84630c529',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VJ&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VJ&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '5e36564d-f8bb-4f14-a76f-e5b84630c529',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T105019783688736_00000000-0000-0000-0000-000000000000_AirlineLogoVJ.webp","info":"Vietjet Air | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '1d918513-d07a-4551-be1b-2de03842e0a1',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=JL&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=JL&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '1d918513-d07a-4551-be1b-2de03842e0a1',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T101108377743098_00000000-0000-0000-0000-000000000000_AirlineLogoJL.webp","info":"Japan Airlines | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '3b9156e5-c9b6-4463-b6fa-871e8af01741',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VJ&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VJ&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '3b9156e5-c9b6-4463-b6fa-871e8af01741',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T105019783688736_00000000-0000-0000-0000-000000000000_AirlineLogoVJ.webp","info":"Vietjet Air | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'bd5d2ed6-fe02-420d-b281-c2d15e12ebd1',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=QH&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=QH&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'bd5d2ed6-fe02-420d-b281-c2d15e12ebd1',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T104738017292739_00000000-0000-0000-0000-000000000000_AirlineLogoQH.webp","info":"Bamboo Airways | Business"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '48059494-8103-4b13-bec5-6b031b0f4256',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=DLI&departure_city=Da+Lat&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=QH&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=DLI&departure_city=Da+Lat&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=QH&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '48059494-8103-4b13-bec5-6b031b0f4256',
          module: 'FLIGHT',
          title: 'Da Lat (DLI) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T104738017292739_00000000-0000-0000-0000-000000000000_AirlineLogoQH.webp","info":"Bamboo Airways | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '30cad1a1-abe8-4c06-94c0-a0c47bf9c2f8',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=DEL&departure_city=Delhi&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=MH&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=DEL&departure_city=Delhi&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=MH&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '30cad1a1-abe8-4c06-94c0-a0c47bf9c2f8',
          module: 'FLIGHT',
          title: 'Delhi (DEL) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T101246961486656_00000000-0000-0000-0000-000000000000_AirlineLogoMH.webp","info":"Malaysia Airlines | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'f76c3e63-637e-4d7a-8493-a256d566cc6f',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=DEL&departure_city=Delhi&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=EK&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=DEL&departure_city=Delhi&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=EK&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'f76c3e63-637e-4d7a-8493-a256d566cc6f',
          module: 'FLIGHT',
          title: 'Delhi (DEL) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T100826009967191_00000000-0000-0000-0000-000000000000_AirlineLogoEK.webp","info":"Emirates | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'eda540b4-215f-4389-93b9-375d7c6a29d9',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=DEL&departure_city=Delhi&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VJ&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=DEL&departure_city=Delhi&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VJ&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'eda540b4-215f-4389-93b9-375d7c6a29d9',
          module: 'FLIGHT',
          title: 'Delhi (DEL) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T105019783688736_00000000-0000-0000-0000-000000000000_AirlineLogoVJ.webp","info":"Vietjet Air | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '94883bf6-9a89-4c37-b9bf-aaf9eee66c34',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VJ&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VJ&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '94883bf6-9a89-4c37-b9bf-aaf9eee66c34',
          module: 'FLIGHT',
          title: 'Bangkok (BKK) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T105019783688736_00000000-0000-0000-0000-000000000000_AirlineLogoVJ.webp","info":"Vietjet Air | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'cabb32e2-4be9-40e2-8383-0502a416e4e7',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=CX&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=BKK&departure_city=Bangkok&arrived=SGN&arrived_city=Ho+Chi+Minh+city&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=CX&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'cabb32e2-4be9-40e2-8383-0502a416e4e7',
          module: 'FLIGHT',
          title: 'Bangkok (BKK) → Ho Chi Minh city (SGN)',
          summary:
            '{"airline_image":"vn_20241022T100730060668800_00000000-0000-0000-0000-000000000000_AirlineLogoCX.webp","info":"Cathay Pacific Airways | Economy"}',
          image:
            'vn_20250124T143252200171806_00000000-0000-0000-0000-000000000000_HoChiMinhSGN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'fbc05cc9-514d-4637-b5c6-d3e8ec19eb09',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&departDate=today&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Premium Economy&airlines=VN&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&departDate=today&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Premium Economy&airlines=VN&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'fbc05cc9-514d-4637-b5c6-d3e8ec19eb09',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T105023170656733_00000000-0000-0000-0000-000000000000_AirlineLogoVN.webp","info":"Vietnam Airlines | Premium Economy"}',
          image: null,
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'df7967e9-e1f3-4aae-a0f1-e40d2568990d',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=DEL&departure_city=Delhi&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=K6&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=DEL&departure_city=Delhi&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=K6&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'df7967e9-e1f3-4aae-a0f1-e40d2568990d',
          module: 'FLIGHT',
          title: 'Delhi (DEL) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T101127431785904_00000000-0000-0000-0000-000000000000_AirlineLogoK6.webp","info":"Cambodia Angkor Air | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '0dd311a1-0fff-4695-8615-d1d8439a07ca',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=PremiumEconomy&airlines=VN&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=PremiumEconomy&airlines=VN&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '0dd311a1-0fff-4695-8615-d1d8439a07ca',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T105023170656733_00000000-0000-0000-0000-000000000000_AirlineLogoVN.webp","info":"Vietnam Airlines | Premium Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: 'dd950cd5-73d5-4856-90df-b5268d868e5d',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=DIN&departure_city=Dien+Bien+Phu&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VN&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=DIN&departure_city=Dien+Bien+Phu&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VN&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: 'dd950cd5-73d5-4856-90df-b5268d868e5d',
          module: 'FLIGHT',
          title: 'Dien Bien Phu (DIN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T105023170656733_00000000-0000-0000-0000-000000000000_AirlineLogoVN.webp","info":"Vietnam Airlines | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '0fe91b30-b10b-423d-92fd-ad615ee302a2',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=CAN&departure_city=Guangzhou&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VN&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=CAN&departure_city=Guangzhou&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Economy&airlines=VN&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '0fe91b30-b10b-423d-92fd-ad615ee302a2',
          module: 'FLIGHT',
          title: 'Guangzhou (CAN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T105023170656733_00000000-0000-0000-0000-000000000000_AirlineLogoVN.webp","info":"Vietnam Airlines | Economy"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
      {
        t1: '3f801691-853c-4844-8700-928cea0b849a',
        t2: {
          absolute_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=VN&cross_sale=true',
          deep_link:
            '/booking/flight/search?optionTrip=false&departure=SGN&departure_city=Ho+Chi+Minh+city&arrived=HAN&arrived_city=Ha+Noi&sorting=LOWEST_PRICE&adults=1&children=0&infant=0&class=Business&airlines=VN&cross_sale=true',
          original_price: -1.0,
          currency: 'USD',
          id: '3f801691-853c-4844-8700-928cea0b849a',
          module: 'FLIGHT',
          title: 'Ho Chi Minh city (SGN) → Ha Noi (HAN)',
          summary:
            '{"airline_image":"vn_20241022T105023170656733_00000000-0000-0000-0000-000000000000_AirlineLogoVN.webp","info":"Vietnam Airlines | Business"}',
          image:
            'vn_20250124T143147193317184_00000000-0000-0000-0000-000000000000_HaNoiHAN',
          price: -1.0,
          rating: 5.0,
        },
      },
    ];
  }
}
