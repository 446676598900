<button (click)="handleClickVoucherPopup()"
    class="font-inter w-[100%] h-[40px] border border-branding-primary-50 bg-branding-primary-50 rounded-lg
                flex justify-center items-center !gap-2 hover:bg-branding-primary-100 hover:scale-105">
    <img class="w-[20px] h-[20px]" src="assets/icons/voucher-payment/voucher-icon.svg" alt="">
    <span class="text-sm font-semibold text-palette-blue-700">
        {{'PAYMENT.SELECT_A_VOUCHER' | translate}}
    </span>
</button>

<!-- Voucher popup -->
<div class="card flex justify-content-center font-inter">
    <p-dialog header="{{'PAYMENT.SELECT_A_VOUCHER' | translate}}"  [(visible)]="isShowVoucherPopup" [modal]="true" [draggable]="false"
        [style]="{ maxWidth: '560px', width: '90vw', height: 'auto' }"  [resizable]="false" (onShow)="handleShowVoucherPopup()" (onHide)="handleCancelPromoVoucher()" [closable]="true">
        <!-- BODY -->
        <div class="bg-white !px-6 !pb-6">
            <ng-container *ngIf="isLogin; else recommendLogin">
                <!-- COINS -->
                <!-- <div class="flex justify-between !pt-6 items-center">
                    <div class="flex gap-2 text-center items-center">
                        <span class="text-base font-inter font-semibold text-palette-gray-700">
                            {{'PAYMENT.VOUCHER.SOCTRIP_COINS' | translate}}</span>
                        <img src="assets/icons/voucher-payment/more-info-voucher-payment.svg" alt="">
                    </div>
                    <div class="text-sm text-palette-gray-500 font-inter">
                        {{'PAYMENT.VOUCHER.YOUR_HAVE' | translate}} {{availableCoins}}
                        {{'PAYMENT.VOUCHER.COINS_AVAILABLE' | translate}}
                    </div>
                </div>
                <div class="soctrip-coins w-[512px]w-[512px] h-[44px] rounded-lg px-4 py-[10px] !mt-3">
                    <div class="flex justify-between items-center">
                        <div class="flex gap-2 items-center">
                            <img class="!w-[20px] !h-[20px]" src="assets/icons/voucher-payment/coins.svg" alt="">
                            <span class="font-inter text-base text-palette-gray-900">{{useCoins}}</span>
                        </div>
                        <p-inputSwitch class="w-[36px] h-[20px]" [disabled]="!useCoins" [(ngModel)]="checked"></p-inputSwitch>
                    </div>
                </div>
                <div class="mt-[6px] font-inter text-sm text-palette-gray-600">
                    {{'PAYMENT.VOUCHER.YOU_CAN_USE_UP_TO' | translate}} {{useCoins}} 
                    {{'PAYMENT.VOUCHER.COINS_FOR_THIS_PAYMENT' | translate}}
                </div> -->
            </ng-container>
            <ng-template #recommendLogin>
                <div class="!mt-6 !px-4 !py-6 flex flex-col items-center gap-2 rounded-lg overflow-hidden bg-red-200 font-inter popup-login">
                    <h3 class="text-xl font-semibold leading-[30px] text-palette-blue-900">{{'CTA.TITLE' | translate}}</h3>
                    <p class="text-sm !font-medium leading-5 !text-palette-gray-900 text-center">{{'CTA.DETAIL' | translate}}</p>
                    <button class="!mt-2 whitespace-nowrap rounded-lg bg-palette-blue-600 hover:bg-palette-blue-700 !h-11 w-fit py-[10px] px-[18px] text-base font-semibold leading-6 text-white"
                            (click)="handleClickSignInOrSignUp()">
                        {{'CTA.SIGN_IN' | translate}}
                    </button>
                </div>
            </ng-template>
            <div class="flex justify-between !mt-4">
                <span class="text-base font-inter font-semibold text-palette-gray-700">
                    {{'PAYMENT.VOUCHER.YOUR_VOUCHERS' | translate}}
                </span>
                <span (click)="handleClickAddPromoCode()"
                    class="text-sm font-semibold font-inter cursor-pointer
                        {{showPromoCodeCard ? 'text-palette-gray-300' : 'text-branding-primary-700 hover:text-branding-primary-600'}}">
                        {{'PAYMENT.VOUCHER.ADD_PROMO_CODE' | translate}}
                    </span>
            </div>
            <!-- PRIVATE VOUCHER INPUT -->
            <div *ngIf="showPromoCodeCard"
                class="promo-code-card w-full rounded !p-4 flex flex-col gap-[6px] mt-[6px] relative">
                <span class="font-inter text-sm font-medium text-palette-gray-700">
                    {{'PAYMENT.VOUCHER.PROMO_CODE' | translate}}
                </span>
                <div class="flex gap-2 relative">
                    <input 
                        class="border {{invalidPromoCode ? 'border-palette-red-300' : 'border-palette-gray-300'}}
                            max-w-[387px] w-full h-[44px] outline-none rounded-lg px-[14px] py-[10px] font-inter text-base text-palette-gray-900 uppercase"
                        [(ngModel)]="promoCode"
                        type="text" 
                        placeholder=" {{'PAYMENT.PLACEHOLDER.ENTER_A_CODE' | translate}}"
                        (input)="handleChangeInput()">

                    <button (click)="applyPromoCode()" [disabled]="!promoCode || isFindingVoucher"
                        class="{{promoCode && !isFindingVoucher ? 'bg-palette-blue-600 hover:bg-palette-blue-500' : 'bg-palette-blue-200'}} 
                            h-[44px] px-[14px] py-[10px] rounded-lg font-inter text-base font-semibold text-white min-w-[74px] whitespace-nowrap flex justify-center items-center">
                        <ng-container *ngIf="!isFindingVoucher">
                            {{'PAYMENT.VOUCHER.APPLY' | translate}}
                        </ng-container>
                        <ng-container *ngIf="isFindingVoucher">
                            <app-loading />
                        </ng-container>
                    </button>
                    <img *ngIf="invalidPromoCode" class="!w-4 h-4 absolute top-3.5 right-[105px] cursor-pointer"
                        src="assets/icons/voucher-payment/invalid_promo_code.svg" alt="">
                </div>
                <span *ngIf="invalidPromoCode" class="font-inter text-sm text-system-error-500">
                    {{'PAYMENT.VOUCHER.CODE_INVALID' | translate}}
                </span>
                <img (click)="handleCancelPromoCodeCard()" class="absolute top-3 right-3 cursor-pointer"
                    src="assets/icons/voucher-payment/cancel-icon.svg" alt="">
            </div>
            <div class="flex flex-col gap-1 mt-1">
                <ng-container *ngIf="!isLoading">
                    <!-- PRIVATE VOUCHER -->
                    <div *ngIf="private_voucher" class="mt-2 relative" (click)="handleSelectVoucher(private_voucher, -1)">
                        <div class="card-voucher flex rounded-lg gap-0 cursor-pointer">
                            <div class="{{indexSelectedVoucher == -1 ? 'private-left-active' : 'private-left'}}
                                            {{!private_voucher.valid ? 'left-card-voucher-inactive' : ''}}
                                            border w-[88px] min-w-[88px] h-[88px] rounded-s-lg flex justify-center items-center overflow-hidden">
                                <img class="!w-[72px] min-w-[72px] !h-[72px]"
                                    src="{{baseUrl}}/storage/files/web/{{private_voucher.avatar_id}}.webp" alt="" (error)="replaceWithDefaultImage($event)"/>
                            </div>
                            <div class="{{indexSelectedVoucher == -1 ? 'private-right-active' : 'private-right'}}
                                            {{!private_voucher.valid ? 'right-card-voucher-inactive' : ''}}
                                            w-[424px] h-[88px] rounded-e-lg px-2 md:px-4 py-3 flex justify-between items-center relative overflow-hidden">
                                <div *ngIf="!private_voucher.valid" 
                                    class="absolute top-0 right-0 !h-5 bg-palette-orange-dark-50 rounded-tr-lg rounded-bl-lg flex items-center gap-2 cursor-auto !px-4">
                                    <i class="sctr-icon-alert-circle text-[11px] leading-[11px] text-palette-orange-700 font-semibold"></i>
                                    <p class="text-palette-orange-700 text-[9px] leading-5 font-semibold font-inter">{{'PAYMENT.VOUCHER.NOT_ELIGIBLE' | translate}}</p>
                                </div>
                                <div>
                                    <div class="font-inter text-sm leading-5 font-medium max-w-[240px] line-clamp-2 {{private_voucher.valid ? 'text-palette-gray-800' : 'text-palette-gray-500'}}">
                                        {{private_voucher.name}}
                                    </div>
                                    <div class="flex items-center gap-[2px] font-inter text-[10px] leading-4 {{private_voucher.valid ? 'text-palette-gray-600' : 'text-palette-gray-400'}}">
                                        <ng-container *ngIf="private_voucher?.voucher_discount_range?.discount_amount">
                                            {{'PAYMENT.VOUCHER.GET' | translate}} <app-price-format [price]="private_voucher.voucher_discount_range.discount_amount"></app-price-format>
                                        </ng-container>
                                        <ng-container *ngIf="private_voucher?.voucher_discount_range?.discount_percent">
                                            {{'PAYMENT.VOUCHER.GET' | translate}} {{ private_voucher.voucher_discount_range.discount_percent }}% {{'PAYMENT.VOUCHER.UP_TO' | translate}} <app-price-format [price]="private_voucher.voucher_discount_range.max_discount_price"></app-price-format>
                                        </ng-container>
                                        
                                    </div>
                                    <div *ngIf="private_voucher?.voucher_discount_range?.min_order_price" class="flex items-center gap-[2px] font-inter text-[10px] leading-4 {{private_voucher.valid ? 'text-palette-gray-600' : 'text-palette-gray-400'}}">
                                        <span>{{'PAYMENT.VOUCHER.MIN_SPEND' | translate}}</span>
                                        <app-price-format [price]="private_voucher.voucher_discount_range.min_order_price"></app-price-format>
                                    </div>
                                    <div
                                        class="text-[10px] leading-4 {{private_voucher.valid ? 'text-palette-gray-600' : 'text-palette-gray-400'}}">
                                        <ng-container *ngIf="private_voucher.end_date">
                                            {{'PAYMENT.VOUCHER.EXPIRED' | translate}} {{private_voucher.end_date | dateFormat }}
                                        </ng-container>
                                        <ng-container *ngIf="!private_voucher.end_date">
                                            {{'PAYMENT.VOUCHER.NO_EXPIRED' | translate}}
                                        </ng-container>
                                    </div>
                                </div>
                                <img *ngIf="private_voucher.valid"
                                    class="w-[20px] h-[20px] cursor-pointer text-red-500"
                                    src="assets/icons/voucher-payment/{{indexSelectedVoucher == -1 ? 'orange-checkbox' : 'checkbox'}}.svg"
                                    alt="">
                            </div>
                        </div>
                        <div>
                            <div class="w-[12px] h-[12px] border border-t-[#fff] {{private_voucher.valid ? 'border-palette-orange-dark-300' : '!border-palette-gray-300'}} 
                                    rounded-full bg-white absolute top-[-6px] left-[82px]
                                    {{indexSelectedVoucher == -1 ? 'border-palette-orange-dark-500' : 'border-palette-orange-dark-300'}}">
                            </div>
                            <div class="w-[12px] h-[6px] rounded-t-[6px] bg-white absolute top-[-6px] left-[82px]">
                            </div>
                        </div>
                        <div>
                            <div class="w-[12px] h-[12px] border border-b-[#fff] {{private_voucher.valid ? 'border-palette-orange-dark-300' : '!border-palette-gray-300'}}
                                        rounded-full bg-white absolute bottom-[-6px] left-[82px]
                                        {{indexSelectedVoucher == -1 ? 'border-palette-orange-dark-500' : 'border-palette-orange-dark-300'}}">
                            </div>
                            <div
                                class="w-[12px] h-[6px] rounded-b-[6px] bg-white absolute bottom-[-6px] left-[82px]">
                            </div>
                        </div>
                    </div>
                    <!-- PUBLIC VOUCHER -->
                    <!-- Valid voucher -->
                    <ng-container *ngIf="public_voucher_valid.length">
                        <ng-container *ngFor="let item of public_voucher_valid; let i = index">
                            <div class="mt-2 relative" *ngIf="i !== indexPublicVoucherApplyByCode" (click)="handleSelectVoucher(item, i)">
                                <div class="card-voucher flex rounded-lg gap-0 cursor-pointer">
                                    <div class="{{i == indexSelectedVoucher ? 'left-card-voucher-active' : 'left-card-voucher'}}
                                                left-card-voucher w-[88px] min-w-[88px] h-[88px] rounded-s-lg flex justify-center items-center overflow-hidden">
                                        <img class="!w-[72px] min-w-[72px] !h-[72px]"
                                            src="{{baseUrl}}/storage/files/web/{{item.avatar_id}}.webp" alt="" (error)="replaceWithDefaultImage($event)"/>
                                    </div>
                                    <div class="{{i == indexSelectedVoucher ? 'right-card-voucher-avtive' : 'right-card-voucher'}}
                                                right-card-voucher w-[424px] h-[88px] rounded-e-lg px-2 md:px-4 flex justify-between items-center relative overflow-hidden">
                                        <div>
                                            <div class="font-inter text-sm leading-5 font-medium max-w-[240px] line-clamp-2 {{item.valid ? 'text-palette-gray-800' : 'text-palette-gray-500'}}">
                                                {{item.name}}
                                            </div>
                                            <div class="flex items-center gap-[2px] font-inter text-[10px] leading-4 {{item.valid ? 'text-palette-gray-600' : 'text-palette-gray-400'}}">
                                                <ng-container *ngIf="item.voucher_discount_range.discount_amount">
                                                    {{'PAYMENT.VOUCHER.GET' | translate}} <app-price-format [price]="item.voucher_discount_range.discount_amount"></app-price-format>
                                                </ng-container>
                                                <ng-container *ngIf="item.voucher_discount_range.discount_percent">
                                                    {{'PAYMENT.VOUCHER.GET' | translate}} {{ item.voucher_discount_range.discount_percent }}% {{'PAYMENT.VOUCHER.UP_TO' | translate}} <app-price-format [price]="item.voucher_discount_range.max_discount_price"></app-price-format>
                                                </ng-container>
                                                
                                            </div>
                                            <div class="flex items-center gap-[2px] font-inter text-[10px] leading-4 {{item.valid ? 'text-palette-gray-600' : 'text-palette-gray-400'}}">
                                                <span>{{'PAYMENT.VOUCHER.MIN_SPEND' | translate}}</span>
                                                <app-price-format [price]="item.voucher_discount_range.min_order_price"></app-price-format>
                                            </div>
                                            <div
                                                class="text-[10px] leading-4 {{item.valid ? 'text-palette-gray-600' : 'text-palette-gray-400'}}">
                                                <ng-container *ngIf="item.end_date">
                                                    {{'PAYMENT.VOUCHER.EXPIRED' | translate}} {{item.end_date | dateFormat }}
                                                </ng-container>
                                                <ng-container *ngIf="!item.end_date">
                                                    {{'PAYMENT.VOUCHER.NO_EXPIRED' | translate}}
                                                </ng-container>
                                            </div>
                                        </div>
                                        <img
                                            class="w-[20px] h-[20px] cursor-pointer"
                                            src="assets/icons/voucher-payment/{{i == indexSelectedVoucher ? 'checkbox-active' : 'checkbox'}}.svg"
                                            alt="">
                                    </div>
                                </div>
                                <div>
                                    <div class="w-[12px] h-[12px] border border-t-[#fff] border-palette-blue-300
                                        rounded-full bg-white absolute top-[-6px] left-[82px]
                                        {{i == indexSelectedVoucher ? '!border-palette-blue-600' : 'border-palette-blue-300'}}">
                                    </div>
                                    <div class="w-[12px] h-[6px] rounded-t-[6px] bg-white absolute top-[-6px] left-[82px]">
                                    </div>
                                </div>
                                <div>
                                    <div class="w-[12px] h-[12px] border border-b-[#fff] border-palette-blue-300
                                        rounded-full bg-white absolute bottom-[-6px] left-[82px]
                                        {{i == indexSelectedVoucher ? '!border-palette-blue-600' : 'border-palette-blue-300'}}">
                                    </div>
                                    <div class="w-[12px] h-[6px] rounded-b-[6px] bg-white absolute bottom-[-6px] left-[82px]">
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <!-- Invalid voucher -->
                    <ng-container *ngIf="public_voucher_invalid.length">
                        <ng-container *ngFor="let item of public_voucher_invalid; let i = index">
                            <div class="mt-2 relative">
                                <div class="card-voucher flex rounded-lg gap-0 cursor-pointer">
                                    <div class="left-card-voucher-inactive w-[88px] min-w-[88px] h-[88px] rounded-s-lg flex justify-center items-center overflow-hidden">
                                        <img class="!w-[72px] min-w-[72px] !h-[72px]"
                                            src="{{baseUrl}}/storage/files/web/{{item.avatar_id}}.webp" alt="" (error)="replaceWithDefaultImage($event)"/>
                                    </div>
                                    <div class="right-card-voucher-inactive w-[424px] h-[88px] rounded-e-lg  px-2 md:px-4 flex justify-between items-center relative overflow-hidden">
                                        <div>
                                            <div class="font-inter text-sm leading-5 font-medium max-w-[240px] line-clamp-2 text-palette-gray-500">
                                                {{item.name}}
                                            </div>
                                            <div class="flex items-center gap-[2px] font-inter text-[10px] leading-4 text-palette-gray-400">
                                                <ng-container *ngIf="item.voucher_discount_range.discount_amount">
                                                    {{'PAYMENT.VOUCHER.GET' | translate}} <app-price-format [price]="item.voucher_discount_range.discount_amount"></app-price-format>
                                                </ng-container>
                                                <ng-container *ngIf="item.voucher_discount_range.discount_percent">
                                                    {{'PAYMENT.VOUCHER.GET' | translate}} {{ item.voucher_discount_range.discount_percent }}% {{'PAYMENT.VOUCHER.UP_TO' | translate}} <app-price-format [price]="item.voucher_discount_range.max_discount_price"></app-price-format>
                                                </ng-container>
                                                
                                            </div>
                                            <div class="flex items-center gap-[2px] font-inter text-[10px] leading-4 text-palette-gray-400">
                                                <span>{{'PAYMENT.VOUCHER.MIN_SPEND' | translate}}</span>
                                                <app-price-format [price]="item.voucher_discount_range.min_order_price"></app-price-format>
                                            </div>
                                            <div
                                                class="text-[10px] leading-4 text-palette-gray-400">
                                                <ng-container *ngIf="item.end_date">
                                                    {{'PAYMENT.VOUCHER.EXPIRED' | translate}} {{item.end_date | dateFormat }}
                                                </ng-container>
                                                <ng-container *ngIf="!item.end_date">
                                                    {{'PAYMENT.VOUCHER.NO_EXPIRED' | translate}}
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="w-[12px] h-[12px] border border-t-[#fff] !border-palette-gray-300
                                        rounded-full bg-white absolute top-[-6px] left-[82px]">
                                    </div>
                                    <div class="w-[12px] h-[6px] rounded-t-[6px] bg-white absolute top-[-6px] left-[82px]">
                                    </div>
                                </div>
                                <div>
                                    <div class="w-[12px] h-[12px] border border-b-[#fff] !border-palette-gray-300
                                        rounded-full bg-white absolute bottom-[-6px] left-[82px]">
                                    </div>
                                    <div class="w-[12px] h-[6px] rounded-b-[6px] bg-white absolute bottom-[-6px] left-[82px]">
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <!-- NO ITEM -->
                    <ng-container *ngIf="!public_voucher_valid.length && !public_voucher_invalid.length && !private_voucher && !isGettingVoucher">
                        <div class="flex flex-col items-center">
                            <img class="w-[130px] h-[130px] aspect-square mt-9 !mb-8" src="assets/images/common-icon/no-item.svg" alt="NoItem">
                            <h3 class="text-palette-gray-900 text-lg font-semibold font-inter mb-[52px]">{{'PAYMENT.VOUCHER.NO_VOUCHER_AVAILABLE' | translate}}</h3>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- SKELETON -->
                <ng-container *ngIf="isLoading || isGettingVoucher">
                    <div class="mt-2 relative" *ngFor="let item of [].constructor(3)">
                        <div class="card-voucher flex rounded-lg gap-0">
                            <div class="left-card-voucher w-[88px] min-w-[88px] h-[88px] rounded-s-lg flex justify-center items-center overflow-hidden">
                                <p-skeleton size="4rem" styleClass="" />
                            </div>
                            <div
                                class="right-card-voucher w-[424px] h-[88px] rounded-e-lg px-4 py-3 flex justify-between items-center">
                                <div>
                                    <p-skeleton height="1rem" width="16rem" styleClass="mb-2" />
                                    <p-skeleton height="1rem" width="8rem" styleClass="mb-2" />
                                    <p-skeleton height="1rem" width="6rem" styleClass="" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="w-[12px] h-[12px] border border-t-[#fff] border-palette-blue-300
                                rounded-full bg-white absolute top-[-6px] left-[82px]">
                            </div>
                            <div class="w-[12px] h-[6px] rounded-t-[6px] bg-white absolute top-[-6px] left-[82px]"></div>
                        </div>
                        <div>
                            <div class="w-[12px] h-[12px] border border-b-[#fff] border-palette-blue-300
                                rounded-full bg-white absolute bottom-[-6px] left-[82px]">
                            </div>
                            <div
                                class="w-[12px] h-[6px] rounded-b-[6px] bg-white absolute bottom-[-6px] left-[82px]">
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <!-- FOOTER -->
        <ng-template pTemplate="footer" class="shadow-lg">
            <button
                class="cancel-voucher-btn !w-[99px] h-[44px] px-[18px] py-[10px] rounded-lg font-inter text-base font-semibold text-palette-gray-700 hover:bg-palette-gray-200"
                (click)="handleCancelPromoVoucher()">{{'COMMON.CANCEL' | translate}}</button>
            <button 
                [disabled]="isConfirmVoucher || (backupIndexSelectedVoucher === null && indexSelectedVoucher === null)"
                class="confirm-voucher-btn h-[44px] px-[18px] py-[10px] mr-0 rounded-lg font-inter text-base font-semibold text-white whitespace-nowrap min-w-[108px]"
                [ngClass]="isConfirmVoucher || (backupIndexSelectedVoucher === null && indexSelectedVoucher === null) ? '!bg-palette-blue-200' : ''"
                (click)="handleConfirmPromoVoucher()">
                <ng-container *ngIf="isConfirmVoucher">
                    <app-loading [style]="'!h-6 !w-6'"></app-loading>
                </ng-container>
                <ng-container *ngIf="!isConfirmVoucher">
                    {{'COMMON.CONFIRM' | translate}}
                </ng-container>
            </button>
        </ng-template>
    </p-dialog>
</div>
<app-toast [keyToast]="'FILE_ERROR'"></app-toast>