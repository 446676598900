import { HttpClient, HttpClientModule } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ApplicationConfig } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { AUTH_INTERCEPTOR_PROVIDER } from './core/interceptors/auth/auth.interceptor';
import { ApiModule as AngularMapLocationServiceApiModule } from '@assistant/angular-map-location-service';
import { ApiModule as AngularTourBuilderServiceApiModule } from '@assistant/angular-tour-builder-service';
import {
  ApiModule as ReservationServiceApiModule,
  BASE_PATH as RESERVATION_SERVICE_API_BASE_PATH,
} from '@booking/booking-reservation-service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import {
  ApiModule as StorageServiceApiModule,
  BASE_PATH as STORAGE_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-storage-service';
import {
  ApiModule as BookingGenericServiceApiModule,
  BASE_PATH as BOOKING_GENERIC_SERVICE_API_BASE_PATH,
} from '@booking/angular-booking-generic-service';
import { environment } from '@env/environment';
import {
  ApiModule as ShipmentServiceApiModule,
  BASE_PATH as SHIPMENT_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-shipment-service';
import {
  ApiModule as BookingPricingApiModule,
  BASE_PATH as BOOKING_PRICING_API_BASE_PATH,
} from '@booking/booking-pricing-service';
import {
  ApiModule as BookingUserDataApiModule,
  BASE_PATH as BOOKING_USER_DATA_API_BASE_PATH,
} from '@booking/angular-booking-user-data-service';
import {
  ApiModule as BookingFlightApiModule,
  BASE_PATH as BOOKING_FLIGHT_API_BASE_PATH,
} from '@booking/angular-booking-flight-search-service';
import {
  ApiModule as EcommerceFinanceApiModule,
  BASE_PATH as ECOMMERCE_FINANCE_API_BASE_PATH,
} from '@soctrip/angular-ecommerce-finance-service';
import {
  ApiModule as EcommerceWalletApiModule,
  BASE_PATH as ECOMMERCE_WALLET_API_BASE_PATH,
} from '@soctrip/angular-ecommerce-wallet-service';
import {
  ApiModule as OrderApiModule,
  BASE_PATH as ORDER_API_BASE_PATH,
} from '@soctrip/angular-order-service';
import {
  ApiModule as BookingReservationApiModule,
  BASE_PATH as BOOKING_RESERVATION_API_BASE_PATH,
} from '@booking/angular-booking-reservation-service';
import {
  ApiModule as RegionalApiModule,
  BASE_PATH as REGIONAL_API_BASE_PATH,
} from '@soctrip/angular-regional-service';
import {
  ApiModule as AirportInfoControllerService,
  BASE_PATH as BOOKING_FLIGHT_BASE_PATH,
} from '@booking/angular-booking-flight-data-service';

import {
  ApiModule as BannerService,
  BASE_PATH as BANNER_BASE_PATH,
} from '@soctrip/angular-banner-service';


import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { registerLocaleData } from '@angular/common';
import localeVi from '@angular/common/locales/vi';
import { MessageService } from 'primeng/api';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { defineCustomElements } from '@soctrip-common/discount-selector/loader';
import { defineCustomElements as defineCustomElementsFeatureArticle } from '@soctrip-common/feature-article/loader';
defineCustomElements(window);
defineCustomElementsFeatureArticle(window)


import {
  ApiModule as CrossSaleModuleNpm,
  Configuration as CrossSaleConfigurationNpm,
} from '@soctrip/angular-cross-sale-service';
export function ApiConfigFactory(path: string) {
  return {
    // set configuration parameters here
    basePath: `${environment.baseURL}/${path}`,
  };
}
import { AppSecurityModule } from '@soctrip-common/app-security';


export function playerFactory() {
  return player;
}

registerLocaleData(localeVi, 'vi');

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    MessageService,
    importProvidersFrom([
      /* @angular */
      HttpClientModule,
      ReactiveFormsModule,
      AngularMapLocationServiceApiModule,
      AngularTourBuilderServiceApiModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => {
            return new TranslateHttpLoader(http, './assets/i18n/', '.json');
          },
          deps: [HttpClient],
        },
      }),
      ReservationServiceApiModule,
      BookingGenericServiceApiModule,
      ShipmentServiceApiModule,
      StorageServiceApiModule,
      BookingPricingApiModule,
      BookingUserDataApiModule,
      BookingFlightApiModule,
      EcommerceFinanceApiModule,
      EcommerceWalletApiModule,
      OrderApiModule,
      BookingReservationApiModule,
      RegionalApiModule,
      AirportInfoControllerService,
      BannerService,
      LottieModule.forRoot({ player: playerFactory }),
      CrossSaleModuleNpm.forRoot(
         () => new CrossSaleConfigurationNpm(ApiConfigFactory('cross-sale'))
       ),
      AppSecurityModule.forRoot(),
    ]),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.socialAuthenIds.google,
              {
                oneTapEnabled: false, // <===== default is true
              }
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              environment.socialAuthenIds.facebook
            ),
          },
        ],
        onError: (err: any) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
      
    },
    provideAnimations(),
    provideRouter(APP_ROUTES),
    AUTH_INTERCEPTOR_PROVIDER,
    {
      provide: RESERVATION_SERVICE_API_BASE_PATH,
      useValue: environment.RESERVATION_SERVICE_API_BASE_PATH,
    },
    {
      provide: BOOKING_GENERIC_SERVICE_API_BASE_PATH,
      useValue: environment.BOOKING_GENERIC_SERVICE_API_BASE_PATH,
    },
    {
      provide: SHIPMENT_SERVICE_API_BASE_PATH,
      useValue: environment.SHIPMENT_SERVICE_API_BASE_PATH,
    },
    {
      provide: STORAGE_SERVICE_API_BASE_PATH,
      useValue: environment.STORAGE_SERVICE_API_BASE_PATH,
    },
    {
      provide: BOOKING_PRICING_API_BASE_PATH,
      useValue: environment.BOOKING_PRICING_API_BASE_PATH,
    },
    {
      provide: BOOKING_USER_DATA_API_BASE_PATH,
      useValue: environment.BOOKING_USER_DATA_API_BASE_PATH,
    },
    {
      provide: BOOKING_FLIGHT_API_BASE_PATH,
      useValue: environment.BOOKING_FLIGHT_API_BASE_PATH,
    },
    {
      provide: ECOMMERCE_FINANCE_API_BASE_PATH,
      useValue: environment.ECOMMERCE_FINANCE_API_BASE_PATH,
    },
    {
      provide: ECOMMERCE_WALLET_API_BASE_PATH,
      useValue: environment.ECOMMERCE_WALLET_API_BASE_PATH,
    },
    { provide: ORDER_API_BASE_PATH, useValue: environment.ORDER_API_BASE_PATH },
    {
      provide: BOOKING_RESERVATION_API_BASE_PATH,
      useValue: environment.BOOKING_RESERVATION_API_BASE_PATH,
    },
    {
      provide: REGIONAL_API_BASE_PATH,
      useValue: environment.REGIONAL_API_BASE_PATH,
    },
    {
      provide: BOOKING_FLIGHT_BASE_PATH,
      useValue: environment.BOOKING_FLIGHT_BASE_PATH,
    },
    {
      provide: BANNER_BASE_PATH,
      useValue: environment.BANNER_BASE_PATH,
    },
  ],
};
