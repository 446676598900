<div class="fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center z-[99] bg-black bg-opacity-40 h-screen w-screen">
    <div class="w-[792px] h-fit max-h-[calc(100vh-40px)] rounded-2xl shadow-custom bg-white !px-6 !py-4 !m-2 flex flex-col overflow-y-auto">
        <div class="flex justify-between items-center !mb-4 border-b border-b-palette-gray-200">
            <h3 class="text-lg font-semibold text-palette-gray-900">
                {{'COMMON.FLIGHT_DETAILS' | translate}}</h3>
            <div (click)="handleClosePopup()"
                class="flex items-center justify-center w-[36px] h-[36px] hover:bg-palette-gray-100 rounded-lg cursor-pointer">
                <i class="sctr-icon-x-close text-[20px] text-palette-gray-700"></i>
            </div>
        </div>
        <div class="flex-1 !py-4 flex flex-col !gap-5 max-h-[calc(100%-204px)] overflow-y-auto">
            <select-button-round-trip
                [itineraries]="flightDetail?.flight_info?.itineraries"
                [currentModeView]="currentModeView"
                (changeViewMode)="changeViewMode($event)">
            </select-button-round-trip>
            <!-- flight-schedule -->
            <flight-schedule 
                [bookingInfo]="flightDetail?.booking_info"
                [segments]="currentSegments"
                [flightInfo]="flightDetail?.flight_info"
                [segment_type]="currentSegmentType"
                [isHidePNRCode]="false"
                [isHideGDSSource]="true">
            </flight-schedule >

            <hr class="border border-dashed">

            <!-- guest info -->
            <guest-info
                [type]="currentModeView"
                [flightDetail]="flightDetail">
            </guest-info>
        </div>
        <div class="flex justify-end items-center !gap-3 !pt-4">
            <button (click)="handleClosePopup()"
                class="!h-10 border border-palette-gray-200 rounded-lg !px-5 flex justify-center items-center hover:bg-palette-gray-50">
                {{ 'COMMON.CLOSE' | translate }}
            </button>
        </div>
    </div>
</div>