import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'flight-details-popup',
    templateUrl: './flight-details-popup.component.html',
    styleUrls: ['./flight-details-popup.component.scss']
})
export class FlightDetailsPopupComponent {
    @Input() bookingInfo: any;

    @Output() closePopupEmit: EventEmitter<any> = new EventEmitter();


    flightDetail: any;
    currentModeView = 'DEPART';
    currentSegments: any;
    currentSegmentType: string;

    constructor(private translateService: TranslateService) { }

    ngOnInit() { }

    handleClosePopup() {
        this.closePopupEmit.emit();
    }

    changeViewMode(mode: string) {
        this.currentModeView = mode;
        // Re init data when mode changes
        const index = this.currentModeView === 'DEPART' ? 0 : 1;
        const itinerary = this.flightDetail.flight_info?.itineraries[index];
        if (itinerary) {
            this.currentSegments = itinerary.segments;
            this.currentSegmentType = itinerary.segment_type;
        }
    }
}
