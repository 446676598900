<div class="!p-4 bg-white rounded-lg border border-palette-gray-200 shadow-sm">
    <p class="text-palette-gray-900 text-base font-semibold">{{ 'FLIGHT.MY_BOOKING.GUEST_INFORMATION' | translate }}</p>
    <!-- TABLE PASSENGER -->
    <div class="relative overflow-x-auto !mt-2 border !rounded-lg border-palette-gray-200 !font-medium">
        <table class="min-w-full">
            <thead
                class="text-sm text-palette-gray-900 font-medium !rounded-t-lg bg-palette-gray-100">
                <tr class="border-b">
                    <td class="rounded-tl-lg sticky left-0 z-10 bg-palette-gray-100 whitespace-nowrap">{{ 'FLIGHT.PAYMENT.PASSENGER' | translate }}</td>
                    <td class="whitespace-nowrap">{{ 'MY_BOOKING.INFORMATION' | translate }}</td>
                    <td class="whitespace-nowrap">{{ 'MY_BOOKING.ADD_ONS' | translate }}</td>
                </tr>
            </thead>
            <tbody>
                <ng-container
                    *ngFor="let passenger of flightDetail?.passenger_information; let index = index; let last = last">
                    <tr [class.border-b]="!last">
                        <td class="!py-2 sticky left-0 z-10 bg-white whitespace-nowrap">
                            <div class="flex flex-col justify-between !gap-2">
                                <div>
                                    <div class="flex flex-row items-center !gap-1 text-sm text-palette-gray-900 font-medium">
                                        <p class="font-medium">{{ ('COMMON.' + (passenger.title | uppercase)) | translate }} 
                                            <span class="uppercase">{{ passenger.middle_and_first_name }} {{ passenger.last_name }}</span>
                                        </p>
                                        <div 
                                            *ngIf="type === 'DEPART' ? passenger?.depart_frequent_flyer_program : passenger?.return_frequent_flyer_program"
                                            (mouseenter)="onMouseEnter(
                                                $event,
                                                type === 'DEPART' ? passenger?.depart_frequent_flyer_program : passenger?.return_frequent_flyer_program,
                                                type === 'DEPART' ? passenger?.depart_frequent_flyer_number : passenger?.return_frequent_flyer_number
                                            )"
                                            (mouseleave)="onMouseLeave()"
                                            class="hover:cursor-pointer relative"
                                        >
                                            <div
                                                class="!w-5 !h-5 flex items-center justify-center text-white
                                                    rounded-full bg-palette-amber-500 font-inter text-xs"
                                            >
                                                M
                                            </div>
                                        </div>
                                    </div>
                                    <p *ngIf="(type === 'DEPART' && passenger?.depart_ticket_number) || (type === 'RETURN' && passenger?.return_ticket_number)"
                                        class="text-palette-gray-500 text-xs mt-[2px]">
                                        {{ 'MY_BOOKING.TICKET_NO' | translate }}: {{ type === 'DEPART' ? passenger.depart_ticket_number : passenger.return_ticket_number }}
                                    </p>
                                    <p class="{{passenger?.passenger_type === 'Infant' || passenger?.passenger_type === 'INF' ? 'text-palette-red-600' : 'text-palette-gray-500'}} 
                                        text-xs mt-[2px]">
                                        {{ 'FLIGHT.PAYMENT.' + passenger.passenger_type.toUpperCase() | translate }} {{ passenger?.passenger_index_type }}
                                    </p>
                                </div>
                                <!-- Change request status -->
                                <div *ngIf="changeRequestList?.[type === 'DEPART' ? 0 : 1]?.[index]?.primaryStatus" class="flex items-center">
                                    <p class="rounded-full !px-2 !h-5 flex justify-center items-center border text-xs font-medium w-fit" 
                                        [ngClass]="{
                                            'border-palette-green-600 text-palette-green-600 bg-palette-green-50' : changeRequestList[type === 'DEPART' ? 0 : 1][index].primaryStatus?.toUpperCase() === 'RESCHEDULED',
                                            'border-palette-pink-600 text-palette-pink-600 bg-palette-pink-50' : changeRequestList[type === 'DEPART' ? 0 : 1][index].primaryStatus?.toUpperCase() === 'CHANGED NAME',
                                            'border-palette-violet-600 text-palette-violet-600 bg-palette-violet-50' : changeRequestList[type === 'DEPART' ? 0 : 1][index].primaryStatus?.toUpperCase() === 'PURCHASED SERVICE',
                                            'border-palette-red-600 text-palette-red-600 bg-palette-red-50' : changeRequestList[type === 'DEPART' ? 0 : 1][index].primaryStatus?.toUpperCase() === 'CANCELED',
                                        }">
                                        {{ getRequestStatus(changeRequestList[type === 'DEPART' ? 0 : 1][index].primaryStatus) | translate }}
                                    </p>
                                    <div class="tooltip-container">
                                        <div *ngIf="changeRequestList[type === 'DEPART' ? 0 : 1][index].uniqueStatuses?.length > 1"
                                            class="tooltip-trigger !ml-2 rounded-full !w-fit !px-2 !h-5 flex justify-center items-center border text-xs font-medium border-palette-blue-600 text-palette-blue-600 bg-palette-blue-50">
                                            +{{ changeRequestList[type === 'DEPART' ? 0 : 1][index].uniqueStatuses?.length - 1 }}
                                        </div>
                                        <div class="status-tooltip-content flex flex-col !gap-2">
                                            <ng-container *ngFor="let item of changeRequestList[type === 'DEPART' ? 0 : 1][index].uniqueStatuses">
                                                <p *ngIf="item !== changeRequestList[type === 'DEPART' ? 0 : 1][index].primaryStatus"
                                                    class="rounded-full !px-2 !h-5 flex justify-center items-center border text-xs font-medium w-fit" 
                                                    [ngClass]="{
                                                        'border-palette-green-600 text-palette-green-600 bg-palette-green-50' : item?.toUpperCase() === 'RESCHEDULED',
                                                        'border-palette-pink-600 text-palette-pink-600 bg-palette-pink-50' : item?.toUpperCase() === 'CHANGED NAME',
                                                        'border-palette-violet-600 text-palette-violet-600 bg-palette-violet-50' : item?.toUpperCase() === 'PURCHASED SERVICE',
                                                        'border-palette-red-600 text-palette-red-600 bg-palette-red-50' : item?.toUpperCase() === 'CANCELED',
                                                    }">
                                                    {{ getRequestStatus(item) | translate }}
                                                </p>
                                            </ng-container>
                                        </div>
                                    </div>
                                    
                                    <div class="!w-[2px] !h-3 bg-palette-gray-200 !mx-[6px]"></div>
                                    <button (click)="handleViewDetails(changeRequestList[type === 'DEPART' ? 0 : 1][index].history)" class="text-sm text-palette-blue-600 hover:text-palette-blue-500">
                                        {{ 'COMMON.VIEW_DETAILS' | translate }}
                                    </button>
                                </div>
                            </div>
                        </td>
                        <td class="!py-2 w-fit">
                            <p class="text-palette-gray-500 text-sm whitespace-nowrap">
                                {{ 'MY_BOOKING.DAY_OF_BIRTH' | translate }}: <span class="text-palette-gray-900">{{ passenger.date_of_birth | dateFormat }}</span>
                            </p>
                            <p *ngIf="passenger.passport_identity" class="text-palette-gray-500 text-sm mt-[2px] whitespace-nowrap">
                                {{ (hasVJCodeFlights ? 'FLIGHT.PASSENGERS.CCCD_NUMBER' : 'FLIGHT.PASSENGERS.PASSPORT_NUMBER') | translate }}: 
                                <span class="text-palette-gray-900">{{ passenger.passport_identity }}</span>
                            </p>
                            <p *ngIf="passenger.phone" class="text-palette-gray-500 text-sm mt-[2px] whitespace-nowrap">
                                {{ 'MY_BOOKING.PHONE_NUMBER' | translate }}: <span class="text-palette-gray-900">{{ passenger.phone }}</span>
                            </p>
                        </td>
                        <td class="!py-2 w-fit">
                            <ng-container *ngIf="passenger?.[targetAddOns]?.length; else noData">
                                <div *ngFor="let addOn of passenger[targetAddOns]; let lastItem = last" class="w-fit {{!lastItem ? '!mb-3' : ''}}">
                                    <div *ngIf="(type === 'DEPART' && departSegments.length > 1) || (type === 'RETURN' && returnSegments?.length > 1)" class="rounded border border-palette-gray-600 !px-2 !py-[2px] text-palette-gray-600 text-xs font-medium w-fit">
                                        {{ addOn.origin_iata_code }} <i class="sctr-icon-arrow-right text-xs font-semibold"></i> {{ addOn.destination_iata_code }}
                                    </div>
                                    <p *ngIf="addOn?.seat" class="text-palette-gray-500 text-sm whitespace-nowrap !mt-1">
                                        {{ 'FLIGHT.PAYMENT.SEAT' | translate }}: <span class="text-palette-gray-900">{{ addOn.seat }}</span>
                                    </p>
                                    <p *ngIf="addOn?.extra_baggage" class="text-palette-gray-500 text-sm !mt-[2px] whitespace-nowrap">
                                        {{ 'FLIGHT.PAYMENT.EXTRA_BAGGAGE_1' | translate }}: 
                                        <span class="text-palette-gray-900">
                                            <ng-container *ngIf="addOn.extra_baggage?.description && (addOn.extra_baggage?.description?.toUpperCase()?.includes('OVERSIZE') ||
                                                addOn.extra_baggage?.description?.toUpperCase()?.includes('HLQK')); else normalWeight">
                                                {{ 'COMMON.OVERSIZE' | translate}} {{addOn.extra_baggage.weight}}kg
                                            </ng-container>
                                            <ng-template #normalWeight>
                                                {{addOn.extra_baggage?.weight}}kg
                                            </ng-template>
                                        </span>
                                    </p>
                                    <div *ngIf="addOn?.meals" class="text-palette-gray-500 text-sm !mt-[2px] flex !gap-1">
                                        <span class="whitespace-nowrap">{{ 'FLIGHT.PAYMENT.MEAL' | translate }}: </span>
                                        <div class="flex flex-col !gap-1 w-fit">
                                            <span *ngFor="let meal of addOn.meals; let last = last" class="text-palette-gray-900 whitespace-nowrap">
                                                {{ meal.meal_name }} x {{ meal.quantity }}<span *ngIf="!last">, </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #noData>
                                <div class="w-[14px] h-[2px] border border-palette-gray-300"></div>
                            </ng-template>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

<!-- Popup Choose a Schedule -->
<ng-container *ngIf="isShowChooseSchedule">
    <div class="fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center z-[99] bg-black bg-opacity-40 h-screen w-screen">
        <div class="w-[384px] h-fit max-h-[calc(100vh-40px)] rounded-2xl shadow-custom bg-white !p-6 !m-2 flex flex-col overflow-y-auto">
            <div class="flex justify-between items-center !mb-6">
                <h3 class="text-lg font-semibold text-palette-gray-900">
                    {{'MY_BOOKING.CHOOSE_SCHEDULE' | translate}}</h3>
                <div (click)="handleCloseChooseSchedule()"
                    class="flex items-center justify-center w-[36px] h-[36px] hover:bg-palette-gray-100 rounded-lg cursor-pointer">
                    <i class="sctr-icon-x-close text-[20px] text-palette-gray-700"></i>
                </div>
            </div>
            <div class="flex-1 flex flex-col !gap-2 max-h-[calc(100%-204px)] overflow-y-auto !pb-2 custom-input">
                <ng-container *ngFor="let item of historyList; let index = index; let last = last">
                    <label [for]="item.reservation_code" 
                        class="flex items-start justify-start !gap-2 !p-3 rounded-lg cursor-pointer border custom-radio
                            {{ chosenSchedule === item.reservation_code ? 'border-palette-blue-600 bg-palette-blue-50': 
                                'bg-white hover:!bg-palette-gray-100'}}">
                        <div class="flex justify-start items-start !mt-2">
                            <p-radioButton
                                [inputId]="item.reservation_code"
                                [(ngModel)]="chosenSchedule"
                                [value]="item.reservation_code">
                            </p-radioButton>
                        </div>
                        <div class="!mt-[4px] leading-[1.1] flex flex-col gap-1 justify-start font-inter w-full">
                            <div class="font-medium text-sm">
                                {{ item.reservation_code }}
                            </div>
                            <div class="flex justify-between items-center !gap-3 w-full">
                                <span class="text-palette-gray-500 text-sm">
                                    {{ 'MY_BOOKING.UPDATE' | translate }}: {{ item?.updated_at | dateFormat : true }}
                                </span>
                                <span [ngClass]="handleClassBookingStatus(flightDetail?.booking_info.booking_status)"
                                    class="py-[2px] px-2 font-inter text-xs font-medium leading-4 rounded-full">
                                    {{getStatus(flightDetail?.booking_info.booking_status) | translate}}
                                </span>
                            </div>
                        </div>
                    </label>
                </ng-container>
            </div>
            <div class="flex justify-end items-center !gap-3 !pt-6">
                <button (click)="handleCloseChooseSchedule()"
                    class="!h-10 border border-palette-gray-200 rounded-lg !px-5 flex justify-center items-center hover:bg-palette-gray-50">
                    {{ 'COMMON.CLOSE' | translate }}
                </button>
                <button (click)="handleConfirmChooseSchedule()"
                    class="!h-10 bg-palette-blue-600 text-white rounded-lg !px-5 flex justify-center items-center hover:bg-palette-blue-500">
                    {{ 'COMMON.CONFIRM' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-container>

<!-- Popup flight details -->
<ng-container *ngIf="isShowFlightDetails">
    <flight-details-popup
        (closePopupEmit)="handleCloseFlightDetails()"></flight-details-popup>
</ng-container>