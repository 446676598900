import { Component, Input, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

enum BOOKING_STATUS {
    CONFIRMED = 'Confirmed',
    CANCELED = 'Canceled',
    REFUNDED = 'Refunded',
    PROCESSING = 'Processing',
    PAYMENT_FAILED = 'Payment failed',
    RESCHEDULED = 'Rescheduled',
    PENDING_REFUND = 'Pending refund',
    REFUND_FAILED = 'Refund failed',
    PENDING_PAYMENT = 'Pending payment',
    ORDER_CHANGED = 'Order changed',
}

@Component({
    selector: 'guest-info',
    templateUrl: './guest-info.component.html',
    styleUrls: ['./guest-info.component.scss']
})
export class GuestInfoComponent {
    @Input() type: string;
    @Input() flightDetail: any;

    tooltipElement: HTMLElement | null = null;
    tooltipWidth: number = 0;
    tooltipHeight: number = 0;
    departSegments: any;
    returnSegments: any;
    passengers: any[] = [];
    isOneWay: boolean;
    targetAddOns: string = '';
    hasVJCodeFlights: boolean = false;
    changeRequestList: any[] = [];
    isShowChooseSchedule: boolean = false;
    historyList: any[] = [];
    chosenSchedule: any;
    isShowFlightDetails: boolean = false;

    constructor(private translateService: TranslateService,) { }

    ngOnInit() {
        this.initData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['type'] && changes['type'].currentValue) {
            this.targetAddOns = this.type === 'DEPART' ? 'depart_add_ons' : 'return_add_ons';
        }
    }

    initData() {
        this.passengers = this.flightDetail?.passenger_information;
        this.departSegments = this.flightDetail?.flight_info?.itineraries[0]?.segments;
        this.isOneWay = this.flightDetail?.flight_info?.one_way;
        this.hasVJCodeFlights = this.flightDetail?.flight_info?.itineraries.some((itinerary: any) => {
            return itinerary?.segments?.[0]?.operating_code === "VJ"
        });
        if (!this.isOneWay) {
            this.returnSegments = this.flightDetail?.flight_info?.itineraries[1]?.segments;
        }

        const typeCounter: { [key: string]: number } = {};

        this.passengers.forEach((passenger) => {
            // Add Index type of passengers
            const type = passenger.passenger_type;
            typeCounter[type] = (typeCounter[type] || 0) + 1;
            passenger.passenger_index_type = typeCounter[type];

            // Format add-ons departure
            passenger.depart_add_ons = this.processAddOns(
                this.departSegments,
                passenger?.departure_extra_baggage || [],
                passenger?.departure_seat || [],
                passenger?.departure_meals || []
            );

            // Format add-ons return
            if (!this.isOneWay) {
                passenger.return_add_ons = this.processAddOns(
                    this.returnSegments,
                    passenger?.return_extra_baggage || [],
                    passenger?.return_seat || [],
                    passenger?.return_meals || []
                );
            }
        });

        // Handle format change request status
        if (this.flightDetail?.cs_passenger_information?.length) {
            this.flightDetail.cs_passenger_information.forEach((itinerary: any, index: number) => {
                itinerary.forEach((passenger: any) => {
                    const combinedArray = [
                        ...(passenger.reschedule || []),
                        ...(passenger.canceled || []),
                        ...(passenger.change_name || []),
                        ...(passenger.add_on || [])
                    ];

                    combinedArray.sort((a, b) => new Date(b.request_at).getTime() - new Date(a.request_at).getTime());

                    let primaryStatus = combinedArray.some(item => item.status?.toUpperCase() === "CANCELED")
                        ? "CANCELED" : combinedArray?.[0]?.status || "";

                    const uniqueStatuses = [...new Set(combinedArray.map(item => item.status))];

                    this.changeRequestList[index] = this.changeRequestList[index]?.length ? this.changeRequestList[index] : [];
                    this.changeRequestList[index].push({
                        history: combinedArray,
                        primaryStatus: primaryStatus,
                        uniqueStatuses: uniqueStatuses
                    });
                });

            });
        }
        // this.changeRequestList[0][0] = {
        //     history: [{
        //         status: "Rescheduled",
        //         reservation_code: "SOCTRIP-123-1",
        //         update_at: '12/10/2025'
        //     },
        //     {
        //         status: "Changed name",
        //         reservation_code: "SOCTRIP-123-2",
        //         update_at: '11/10/2025'
        //     },
        //     {
        //         status: "Purchased service",
        //         reservation_code: "SOCTRIP-123-3",
        //         update_at: '10/10/2025'
        //     },
        //     ],
        //     primaryStatus: "Rescheduled",
        //     uniqueStatuses: ["Rescheduled", "Changed name", "Purchased service"]
        // }
    }

    processAddOns(segments: any[], baggageList: any[], seatList: any[], mealList: any[]) {
        const addOnsConvert: any = {};

        // Process baggage
        baggageList?.forEach((baggage: any) => {
            const key = `${baggage.origin_iata_code}_${baggage.destination_iata_code}`;
            addOnsConvert[key] = addOnsConvert[key] || { seat: null, extra_baggage: null, meals: null };
            addOnsConvert[key].extra_baggage = {
                weight: baggage.weight,
                unit: baggage.unit,
                description: baggage.description
            };
        });

        // Process seat
        seatList?.forEach((seat: any) => {
            const key = `${seat.origin_iata_code}_${seat.destination_iata_code}`;
            addOnsConvert[key] = addOnsConvert[key] || { seat: null, extra_baggage: null, meals: null };
            addOnsConvert[key].seat = seat.code;
        });

        // Process meals
        mealList?.forEach((meal: any) => {
            const key = `${meal.origin_iata_code}_${meal.destination_iata_code}`;
            addOnsConvert[key] = addOnsConvert[key] || { seat: null, extra_baggage: null, meals: null };
            addOnsConvert[key].meals = meal.meals;
        });

        // Sort add-ons follow segments
        const add_ons: any[] = [];
        segments.forEach((segment: any) => {
            const departure = segment.departure.iata_code;
            Object.entries(addOnsConvert).forEach(([key, value]: [string, any]) => {
                const [origin_iata_code, destination_iata_code] = key.split('_');
                if (origin_iata_code === departure) {
                    add_ons.push({
                        origin_iata_code,
                        destination_iata_code,
                        ...value,
                    });
                }
            });
        });

        return add_ons;
    };

    onMouseEnter(event: MouseEvent, frequentFlyerProgram: any, frequentFlyerNumber: any) {
        const x = event.pageX;
        const y = event.pageY;
        const html = `
      <div class="!px-4 !py-3 bg-white rounded-lg text-black font-semibold text-xs shadow-lg opacity-1 max-w-xs">
          <div>
              <p class="font-inter text-xs !font-semibold text-palette-gray-500">${this.translateService.instant('FLIGHT.PASSENGERS.MEMBERSHIP')}</p>
              <div class="font-inter text-sm !font-normal text-palette-gray-900">
                  <img />
                  <span>${frequentFlyerProgram}</span>
                  ${frequentFlyerNumber ? `<span> - </span><span>${frequentFlyerNumber}</span>` : ''}
              </div>
          </div>
      </div>
    `

        if (!this.tooltipElement) {
            this.tooltipElement = document.createElement('div');
            this.tooltipElement.innerHTML = html;
            this.tooltipElement.style.position = 'absolute';
            this.tooltipElement.style.zIndex = '9999';
            document.body.appendChild(this.tooltipElement);
            this.tooltipWidth = this.tooltipElement.offsetWidth;
            this.tooltipHeight = this.tooltipElement.offsetHeight;
        }

        this.tooltipElement.style.left = x - 73 + 'px';
        this.tooltipElement.style.top = y - 77 + 'px';
    }

    onMouseLeave() {
        if (this.tooltipElement && this.tooltipElement.parentNode) {
            this.tooltipElement.parentNode.removeChild(this.tooltipElement);
            this.tooltipElement = null;
        }
    }


    public isNumber(value: any): boolean {
        return typeof value === 'number';
    }

    getRequestStatus(status: string): string {
        switch (status?.toUpperCase()) {
            case "RESCHEDULED":
                return 'MY_BOOKING.RESCHEDULED';
            case "CHANGED NAME":
                return 'MY_BOOKING.CHANGED_NAME';
            case "PURCHASED SERVICE":
                return 'MY_BOOKING.PURCHASED_SERVICE';
            case "CANCELED":
                return 'MY_BOOKING.CANCELED';
            default:
                return '';
        }
    }

    handleViewDetails(history: any) {
        this.historyList = history;
        this.isShowChooseSchedule = true;
    }

    handleCloseChooseSchedule() {
        this.isShowChooseSchedule = false;
    }

    handleConfirmChooseSchedule() {
        this.isShowChooseSchedule = false;
        this.isShowFlightDetails = true;
    }

    handleCloseFlightDetails() {
        this.isShowFlightDetails = true;
    }

    handleClassBookingStatus(status: string) {
        switch (status) {
            case BOOKING_STATUS.ORDER_CHANGED:
            case BOOKING_STATUS.CONFIRMED:
            case BOOKING_STATUS.RESCHEDULED:
                return 'bg-palette-emerald-50 text-palette-emerald-600';
            case BOOKING_STATUS.CANCELED:
            case BOOKING_STATUS.REFUNDED:
            case BOOKING_STATUS.PENDING_REFUND:
                return 'bg-palette-gray-100 text-palette-gray-700';
            case BOOKING_STATUS.PROCESSING:
                return 'bg-palette-amber-50 text-palette-amber-700';
            case BOOKING_STATUS.REFUND_FAILED:
            case BOOKING_STATUS.PAYMENT_FAILED:
                return 'bg-palette-red-50 text-palette-red-600';
            case BOOKING_STATUS.PENDING_PAYMENT:
                return 'text-palette-orange-600 bg-palette-orange-50';
            default:
                return '';
        }
    }

    getStatus(status: string): string {
        switch (status) {
            case "Pending booking":
                return "COMMON.PENDING_BOOKING";
            case "Confirmed":
                return "COMMON.CONFIRMED";
            case "Rescheduled":
                return "COMMON.RESCHEDULED";
            case "Processing":
                return "COMMON.PROCESSING";
            case "Completed":
                return "COMMON.COMPLETED";
            case "Pending refund":
                return "COMMON.PENDING_REFUND";
            case "Refunded":
                return "COMMON.REFUNDED";
            case "Refund failed":
                return "COMMON.REFUND_FAILED";
            case "Canceled":
                return "COMMON.CANCELED";
            case "Payment failed":
                return "COMMON.PAYMENT_FAILED";
            case "Pending payment":
                return "COMMON.PENDING_PAYMENT";
            case "Order changed":
                return "COMMON.ORDER_CHANGED"
            default:
                return status;
        }
    }
}
